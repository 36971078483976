import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';

import { updateJob } from '../actions';
import UpdateJob from '../components/UpdateJob';
import Seo from '../components/seo';
import Utils from '../utils';
import { getJobGQL } from '../apollo/queries';
import Message from '../components/Message';

const UpdateJobPage = () => {
  const data = useSelector(({ userData }) => userData);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [hasPermission, setHasPermission] = useState(true);
  const [updateOneJob] = Utils.useActions([updateJob]);
  const [getOneJob] = useLazyQuery(getJobGQL, {
    onCompleted: (results) => {
      if (data.groups.indexOf('hr') > -1) {
        setHasPermission(false);
        return;
      }

      if (results?.job?.error) {
        setErrorMessage(true);
        return;
      }

      if (results && results.job) {
        updateOneJob(results.job);

        if (
          data.groups.indexOf('client') > -1 &&
          data.editingCompany.indexOf(results.job.editingCompany) === -1
        ) {
          setHasPermission(false);
          return;
        }

        setHasPermission(true);
        setShowForm(true);
        return;
      }

      setErrorMessage(true);
    },
    onError: () => {
      setErrorMessage(true);
    },
  });

  useEffect(() => {
    const id = Utils.getSearchParam('id');

    if (id) {
      getOneJob({ variables: { id } });
    } else {
      setErrorMessage(true);
    }
  }, []);

  return (
    <>
      <Seo title="Update a Job" />
      {errorMessage && hasPermission && !showForm && (
        <Message message="An error occurred. Please check if the job posting exists." />
      )}
      {!errorMessage && !hasPermission && !showForm && (
        <Message message="An error occurred. Please check if you have permission to update this job posting." />
      )}
      {!errorMessage && hasPermission && !showForm && (
        <Message message="Loading..." />
      )}
      {!errorMessage && hasPermission && showForm && <UpdateJob />}
    </>
  );
};

export default UpdateJobPage;
