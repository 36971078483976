import React from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';

import { updateJobGQL } from '../apollo/mutations';
import { updateJob } from '../actions';
import Utils from '../utils';

import JobForm from './JobForm';

const UpdateJob = () => {
  const data = useSelector(({ jobData }) => jobData);
  const [updateOneJob] = Utils.useActions([updateJob]);
  const [updateJobPosting] = useMutation(updateJobGQL, {
    onCompleted: (results) => {
      if (results === null) {
        toast.error('An error occurred. Failed to update job posting.');
      } else {
        updateOneJob(results.updateJob);
        toast.success('Job successfully updated.');
        setTimeout(() => {
          navigate('/');
        }, 500);
      }
    },
    onError: () => {
      toast.error('An error occurred. Failed to update job posting.');
    },
  });
  const jobId = Utils.getSearchParam('id');
  const defaultFormValues = jobId
    ? data
        .filter(({ id }) => id === jobId)
        .map(
          ({
            title,
            company: { name, url },
            type,
            category,
            workExperience,
            benefits,
            location: { country, city },
            salary,
            skillRequirements,
            description,
          }) => ({
            title,
            company: { name, url },
            type,
            category,
            id: jobId,
            workExperience,
            benefits: benefits.map((txt) => ({ id: undefined, name: txt })),
            location: { country, city },
            salary,
            skillRequirements: skillRequirements.map((txt) => ({
              id: undefined,
              name: txt,
            })),
            description,
          })
        )[0]
    : {};

  return defaultFormValues?.title ? (
    <JobForm
      defaultFormValues={{ ...defaultFormValues }}
      graphqlQuery={updateJobPosting}
      submitButton={{ title: 'Update job posting', text: 'Update Job' }}
    />
  ) : (
    ''
  );
};

export default UpdateJob;
